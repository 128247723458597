import './App.css';
import {useState,useEffect} from "react";
import Swal from 'sweetalert2'
function App() {
  const [count, setCounter] = useState(0)
  useEffect(() => {
    window.addEventListener("contextmenu", function (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Can't inspect element!",
    });
      e.preventDefault()
      })
    })
  return (
    <>
      <h1>hitung sa {count}</h1>
      <button onClick={() => setCounter((prev)=> prev+1)}>count</button>
      <button onClick={() => setCounter((prev) => prev?prev-1:0)}>count</button>
    </>
  );
}

export default App;
